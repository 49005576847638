export const MOCK_TIMELINE = [
    {
        label: 'February 2024',
        list: [
            { key: '', value: '' }
        ]
    },
    {
        label: 'July 2024',
        list: [
            { key: '', value: '' }
        ]
    },
    {
        label: 'September 2024',
        list: [
            { key: '', value: '' }
        ]
    },
    {
        label: 'January 2025',
        list: [
            { key: '', value: '' }
        ]
    },
]